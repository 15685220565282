import React, { useEffect, useState } from "react";
import recognition from "../Assets/recognition.png";
import appreciation from "../Assets/appreciation.png";
import celebration from "../Assets/celebration.png";
import gratitude from "../Assets/gratitude.png";
import gift from "../Assets/gift.png";
import { useMediaQuery } from "@mui/material";
import Feeds from "./feed";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { findUserByQR } from "../API/api_endpoints";
import branch from 'branch-sdk'; // Import the Branch SDK for web
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UserFeeds from "./user-feeds";
import { text_formatter } from "../utils";
const reviewTypes = [
  { src: recognition, label: "Recognize", value: "RECOGNITION" , icon:"handshakeCount"},
  { src: appreciation, label: "Appreciate", value: "APPRECIATION", icon:"thumbsupCount" },
  { src: celebration, label: "Celebrate", value: "CELEBRATION", icon :"cleberateCount"},
  { src: gratitude, label: "Gratitude", value: "GRATITUDE" , icon:"thankyouCount"},
];
const UserDetails = ({ giveGift = () => { },userDetails ,err, feedDetails, count}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  const { id } = useParams();
  const navigate = useNavigate();
  // const [userDetails, setUserDetails] = useState({});
  const [scanData, setScanData] = useState({})
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleGratitudeClick = (item) => {
    giveGift(item);
  };
  useEffect(() => {
    localStorage.setItem("userid", id);

    branch.init('key_test_nuhRPTcqs2pXIre2P2WApipbtteTPMkT', (err, data) => {
      if (err) {
        setError("Failed to initialize Branch SDK");
        setLoading(false);
        return;
      }

      setScanData(data)
      if (data && data.data_parsed && data.data_parsed.id) {
        const incomingId = data.data_parsed.id;
        localStorage.setItem("userid", incomingId || id);

        if (incomingId) {
          navigate(`/user-details/${incomingId}`);
        }
      }

      setLoading(false);
    });

    // if (id) {
    //   findUserByQR({ _id: id, profilePage: true })
    //     .then((response) => {
    //       setUserDetails(response?.data?.data?.userDetails);
    //       setFeedDetails(
    //         response?.data?.data?.userFeed
    //           .filter((item) => item.gratitudeType === "REVIEW")
    //           .slice(0, 4)
    //       );
    //       setLoading(false);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching user details:", error);
    //       setError("Failed to fetch user details");
    //       setLoading(false);
    //     });
    // }

  }, [id, navigate]);
  if (loading) return <p>Loading...</p>;
  if (error) return <h3>Invalid QR Found</h3>;

  console.log( userDetails?.associatedWithWorkPlaceDetails,"ll")
  return (
    <div className="w-[100%]">



     <div className="w-[95%] mx-[auto]">
     <div className="w-full flex flex-col text-center px-[2rem] pb-6 items-center gap-1">
        <p className="text-[#1D1546] font-bold text-xl w-full">
          <div style={userDetails?.premium?.isProfileVerified ? { display: "flex", gap: "8px", justifyContent: "center", alignItems: "center" } : null}>
            {userDetails?.premium?.isProfileVerified || userDetails?.isNgoVerified?
              <CheckCircleIcon sx={{ fontSize:"20px",  color: userDetails?.premium?.isProfileVerified
                ? "#55A55E"
                : userDetails?.isNgoVerified
                ? "#116EF3"
                : "",   marginRight:"5px"}} /> : ""}
            {text_formatter(userDetails?.userName)}</div>
        </p>
        {userDetails?.profession &&
          <p className="text-[#1D1546] font-normal text-xs w-full">
            {userDetails?.profession}
          </p>}
          {/* {userDetails?.npoName &&   <p className="text-[#1D1546] font-normal text-base w-full">
            {userDetails?.npoName}
          </p>} */}
        {(userDetails?.workPlace) &&(
          <p className="text-[#1D1546] font-normal text-xs w-full">{userDetails?.workPlace|| userDetails?.associatedWithWorkPlaceDetails?.workPlace}
          </p>)}
        {userDetails?.bio &&
          <p className="text-[#1D1546] font-normal text-xs w-full">
            {userDetails?.bio}
          </p>}
      </div>

      
   
      <div className="flex flex-col gap-5">
        <div
        style={{position:"relative",zIndex:"9999"}}
          className={`flex  ${isMobile ? "gap-2 justify-center" : "gap-1 justify-between "
            }`}
        >
          {reviewTypes?.map((items) => (
            <button
              key={items?.value}
              className="flex flex-col border relative border-[#979797] h-[5rem] w-[22%] rounded-[0.625rem] justify-end gap-0.5 py-3 px-3 border-opacity-[30%] items-center"
              onClick={() => handleGratitudeClick(items.value)}
              value={items.value}
            >
              <img src={items.src} alt="" className="w-[1.5rem] h-[1.5rem]" />
              <p className="text-[#1D1546] font-medium text-xs hover:cursor-pointer">
                {items.label}
              </p>
             { count ?  <span className="text-[0.6rem] flex items-center justify-center w-[25px] h-[25px] leading-none absolute rounded-full font-semibold rounded-[4px] top-[-13px] text-white bg-[#FF8245]">+ {count?.[items?.icon]}</span>:null}
            </button>
          ))}
        </div>
        <button
          className="flex justify-center gap-3 border border-[#FF6F29] w-full h-[3.75rem] rounded-[0.625rem] items-center"
          onClick={() => handleGratitudeClick("gift")}
        >
          <img src={gift} alt="" className="w-[1.313rem] h-[1.625rem]" />
       {( userDetails?.businessType === "NON-PROFIT") ?<p className="text-[#FF6F29] font-normal text-sm">Donate</p> :<p className="text-[#FF6F29] font-normal text-sm">Give Gift</p>}   
        </button>
      
        {/* <Feeds feedDetails={feedDetails} /> */}
      </div>
      </div>
          <UserFeeds feedDetails={feedDetails} userDetails={userDetails} socialDetails={userDetails?.socialLinks}/>
    </div>
  );
};
export default UserDetails;