import { useState } from "react";
import Feeds from "./Feeds";
import Media from "./Media";
import Social from "./Social";
import Teams from "./Teams";

const UserFeeds = ({feedDetails, userId, userDetails, socialDetails, branchId }) => {
  const links = ["Feed", "Photos/Videos", "Socials"];
  const [pathName, setPathName] = useState("Feed");

  console.log(feedDetails, "feedDetails")
  const onClickHandler = (link) => {
    setPathName(link);
  };

  const renderComponent = (feedDetails, userId, socialDetails) => {
    switch (pathName) {
      case "Feed":
        return <div className="mt-8 flex flex-wrap gap-4 justify-center bg-[#FFEEEA] p-[10px]">
          <Feeds feedDetails={feedDetails}/>
        </div>
      case "Photos/Videos":
        return <Media userId={userId}/>
      case "Socials":
        return <Social socialDetails={socialDetails}/>
      default:
        return null;
    }
  };

  return (
    <div>
      <Teams userDetails={userDetails}/>
      <div className="flex justify-center items-center mt-8 cursor-pointer w-[95%] mx-auto">
      {links.map((link) => (
      <span
      key={link}
      className={`pb-[1rem] text-xs text-center cursor-pointer  flex-1 ${pathName === link ? "border-b-2 border-[#FF8245] text-[#FF8245]" : "text-[#1D1546]"}`}
      onClick={() => onClickHandler(link)}
    >
          {link}
        </span>
      ))}
     
    </div>
       {renderComponent(feedDetails, userDetails._id, socialDetails)} 
    </div>
  );
};

export default UserFeeds;
