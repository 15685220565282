import React from 'react';
import UserDetails from './user_details';

const GradientCircularProgress = ({ value, userDetails }) => {
  const radius = 80; // Radius of the circle
  const normalizedRadius = radius - 5; // Adjust for stroke width
  const circumference = normalizedRadius * 2 * Math.PI; // Calculate circumference
  const offset = circumference - (value / 100) * circumference; // Calculate the offset



  const getGradientId = () => {
    if (userDetails?.premium?.isProfileVerified) {
      return "gradient-verified";
    } else if (userDetails?.isNgoVerified) {
      return "gradient-ngo-verified";
    } else {
      return "gradient-default";
    }
  };
  return (
      <svg width="250" height="250">
     <defs>
          <linearGradient id="gradient-verified" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#55A55E', stopOpacity: 1 }} />
            <stop offset="50%" style={{ stopColor: '#55A55E', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#55A55E', stopOpacity: 1 }} />
          </linearGradient>
          <linearGradient id="gradient-ngo-verified" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#116EF3', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#116EF3', stopOpacity: 1 }} />
          </linearGradient>
          <linearGradient id="gradient-default" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: '#FF6F29', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#FF6F29', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
      <circle
         stroke={`url(#${getGradientId()})`}
        fill="transparent"
        strokeWidth="6"
        strokeDasharray={circumference + ' ' + circumference}
        style={{ strokeDashoffset: offset }}
        r="80"
        cx="100" // Centered for increased size
        cy="100" // Centered for increased size
      />
    </svg>
  );
};

export default GradientCircularProgress;

