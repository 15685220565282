import React, { useEffect, useState } from "react";
import image from "../Assets/user_profile.jpg";

import Download from "../components/Download";
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import UserDetails from "../components/user_details";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PayerDetails from "../components/payerDetails";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {   findUserByQR } from "../API/api_endpoints";
import CircularGradientProgress from "../components/CircularProgress"
const UserProfile = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [giveGift, setGiveGift] = useState("");
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [feeds, setFeeds] = useState([]);
  const [count, setCount] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleBack = () => {
    setGiveGift("");
  };
  const { id } = useParams();
  console.log(id, "id")
  const location = useLocation(); // Accessing location to get the query string

  // Parsing the query string from the location
  const queryParams = new URLSearchParams(location.search);
  console.log(queryParams.get, "ll", location.search?.split("&")[2]);
  const query = queryParams.get(
    "show" || "payment_intent_client_secret" || "payment_intent"
  ); // Assuming 'query' is the name of your query param

  console.log({   query });
  const handleClickOk = () => {
    const currentUrl = window.location.href;  // Get the current URL

    const url = new URL(currentUrl);          // Create a URL object
  
    console.log({ url }, url?.searchParams);  // Log the URL and its search params

    // Remove multiple query parameters one by one
    const paramsToRemove = [
      "show",
      "payment_intent_client_secret",
      "payment_intent",
      "redirect_status"
    ];
  
    paramsToRemove.forEach(param => url.searchParams.delete(param));  // Delete each parameter
    setOpen(true);

    // Replace the URL in the browser history without reloading the page
    window.history.replaceState({}, '', url.toString());
    window.location.replace(url.toString());

  };
  console.log({open},query==="true","upalikhanduri")
  useEffect(() => {
    setLoading(true);
    localStorage.setItem("userid", id);

    if (id) {
      findUserByQR({ _id: id, profilePage: true })
        .then((response) => {
          setUserDetails(response?.data?.data?.userDetails);
          setFeeds(response?.data?.data?.userFeed);
          setCount(response?.data?.data?.iconsCount);
          setLoading(false);
          setErr(!response?.status);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
      // findGallery().then((response)=>{console.log(response, "gallery")})
    }

  
  }, [id, navigate]);
  return loading ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 200px)",
      }}
    >
      <CircularProgress sx={{ color: "rgb(255 111 41)" }} />
    </div>
  ) : err ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100vh - 300px)",
      }}
    >
      <div
        className={` w-[55%] p-[2rem] mt-[8rem] bg-white rounded-3xl h-fit w-65% min-height-50%   relative flex flex-col gap-4  flex-wrap items-center `}
      >
        <p style={{ fontSize: "20px", color: "#1D1546", fontWeight: "800" }}>
          OOPS!!
        </p>
        <p style={{ fontSize: "18px", color: "#1D1546", fontWeight: "600" }}>
          No account exists with this credentials
        </p>
      </div>
    </div>
  ) : (
    <>
      <div
        className={`flex gap-[4rem] w-full mt-[8rem] justify-center   ${
          isMobile ? "flex-wrap" : ""
        }`}
      >
        <div
          className={`bg-white rounded-3xl h-fit ${
            isMobile ? "min-w-95%" : "w-1/2 md: w-[60%]"
          } relative flex flex-col gap-2 pb-5 flex-wrap items-center `}
        >
          <div className=" w-fit flex flex-col text-center px-[4.5rem] pt-[5rem] relative items-center gap-4 ">
          <div  style={{
                  width: "185px",
                   position: "absolute",
                  transform: "translateY(-100%) rotate(127deg) translateX(13%)"}}>
            <CircularGradientProgress value={85} userDetails={userDetails}/>
          </div>
            <img
              src={
                loading
                  ? null
                  : userDetails?.profilePicture
                  ? userDetails?.profilePicture
                  : image
              }
              alt=""
              className="absolute  transform -translate-y-[114%] h-[8rem] w-[8rem] rounded-full bg-white  "
            />
            {/* <img
            src={red}
            alt=""
          /> */}
          </div>
          {giveGift.length != 0 && (
            <div className="absolute top-0 left-0 w-[4rem] h-[4rem] flex items-center justify-center cursor-pointer">
              <ArrowBackIcon
                className="text-[#979797] w-[1.5rem] h-[1.5rem]"
                onClick={handleBack}
              />
            </div>
          )}
          {giveGift.length == 0 && (
            <UserDetails
              giveGift={setGiveGift}
              userDetails={userDetails}
              err={err}
              feedDetails={feeds}
              count={count}
            />
          )}
          {giveGift.length != 0 && (
            <PayerDetails review={giveGift} userDetails={userDetails} />
          )}
        </div>

        {!isMobile && (
          <div
            className={` ${isMobile ? "min-w-95%" : err ? "" : "w-1/2"} mt-0`}
          >
            <Download err={err} />
          </div>
        )}
<Modal
            open={
              query !== null ||
              location.search?.split("&")[2] == "redirect_status=succeeded"
            }
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box
              sx={{
                width: 400,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 28,
                pt: 4,
                px: 4,
                pb: 4,
                border: "none",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "20px",
              }}
            >
              <h1
                style={{
                  color: "black",
                  fontWeight: "600",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                Congratulations!
              </h1>
              <p
                style={{
                  padding: "20px 0px",
                  color: "black",
                  fontWeight: "500",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                Your recognition has been sent.{" "}
              </p>
              <button
                onClick={handleClickOk}
                style={{
                  padding: "10px 20px",
                  width: "150px",
                  background: "orange",
                  border: "1px solid black",
                  borderRadius: "20px",
                  color: "black",
                  fontWeight: "800",
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                OK
              </button>
            </Box>
          </Modal>
      </div>
    </>
  );
};

export default UserProfile;
