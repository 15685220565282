import { useState } from "react";

const ImageSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [debounce, setDebounce] = useState(false);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  const handleScroll = (event) => {
    if (!debounce) {
      setDebounce(true);
      setTimeout(() => {
        setDebounce(false);
      }, 1000);

      if (event.deltaX > 0) {
        nextSlide();
      } else if (event.deltaX < 0) {
        prevSlide();
      }
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div
        className="w-full h-[14rem] overflow-hidden rounded-[10px] transition-transform duration-500"
        onWheel={handleScroll}>
        <div
          className="flex"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
          {images.map((image, index) => (
            <div
              key={index}
              className="w-full h-full flex-shrink-0"
              style={{ minWidth: "100%" }}>
              <img
                src={image.imgUrl}
                className="w-full h-full object-cover rounded-[10px]"
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center">
        {images?.length >1 ?images?.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 bg-gray-300 rounded-full mx-1 cursor-pointer ${
              index === currentSlide ? "bg-gray-500" : ""
            }`}
            onClick={() => setCurrentSlide(index)}></div>
        )):null}
      </div>
    </div>
  );
};

export default ImageSlider;
