// const API_URL = "https://grati-preprod.resourcifi.tech/grati/api/";
const API_URL = "https://devapigrati.resourcifi.tech/api/";


const API = {
  FIND_USER: API_URL + "gratitude/findUser",
  FIND_GALLERY:API_URL+"user/v2/fetchUserGallery",
  CREATE_GRATITUDE: API_URL + "gratitude/createGratitude",
  IMG_GRATITUDE: API_URL + "gratitude/createImageGratitude",
   FIND_TEAMS:API_URL + "user/getAssociateMember",
  CREATE_WEB :API_URL+"user/v2/createWebUser",
  PIFORBOTH:API_URL+"payment/pi-forBoth",
  USERPROFILECLICK:API_URL+"user/userProfileClick",
 CREATE_PAYMENT_INTENT: API_URL+"payment/create-payment-intent",
 CREATE_DONATION_INTENT:API_URL+"payment/create-donation-intent",
 WEB_PROD_URL:"https://user.grati.community/user_profile",
 WEB_STAGING_URL:"https://user.resourcifi.tech/user_profile",
 STRIPE_LIVE_KEY :"pk_live_51NhHNZFDxlsdZq5eOTANpdboJTGmN9t3tSlXaYHvpxpNISVKNADW633qbIo4axnkwoNK0l0J9PTGTIeRuOT5TPsO00o22sYbza",
 STRIPE_TEST_KEY:"pk_test_51NhHNZFDxlsdZq5eQisMgNxM7vDvVG9SO0Le60jOpQa6KwSxbu9xiP9D8B5oyUQrbn25bu6SBlNhedgMWgSkwaDq00KXlVG4TR",
 FETCH_SPOTLIGHT:API_URL+"gratitude/v2/fetchSpotLight"
};
export default API;
