import React, { useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Snackbar } from "@mui/material";

const SocialLinks = ({ socialDetails }) => {
  const [socialName, setSocialName] = useState("");

  const links = [
    {
      name: "Facebook",
      icon: <FacebookIcon sx={{ color: "#1D1546" }} />,
      link: socialDetails.FBlink,
    },
    {
      name: "Instagram",
      icon: <InstagramIcon sx={{ color: "#1D1546" }} />,
      link: socialDetails.InstaLink,
    },
    {
      name: "LinkedIn",
      icon: <LinkedInIcon sx={{ color: "#1D1546" }} />,
      link: socialDetails.LinkedInLink,
    },
  ];

  const socialClickHandler = (name, value) => {
    setSocialName(name);
  };
  return (
    <div className="p-4 bg-white">
      {links.map((link, index) => (
        <div
          key={index}
          className="flex justify-between items-center p-2 border border-[#979797] border-opacity-[30%] rounded-lg shadow-md mb-4 cursor-pointer"
          onClick={() => {
            socialClickHandler(link.name, index);
          }}
        >
          <div className="flex gap-2 items-center">
            {link.icon}
            <div className="flex flex-col gap-2">
              <span className="text-xs text-[#1D1546]">{link.name}</span>
              <span className="text-xs text-[#1D1546]">
                {link.link || "NA"}
              </span>
            </div>
          </div>

          {/* <button className="text-orange-500">
            <i className="fas fa-edit"></i> 
          </button> */}
        </div>
      ))}
        <Snackbar
      open={socialName }
      autoHideDuration={1000}
      onClose={() => {
        setSocialName("");
        // setOpen(false)
      }}
      message="Download app to avail this feature"
      sx={{ background: "#000", color: "#fff" }}
    />
    </div>
  );
};

export default SocialLinks;
