import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { findGallery } from "../../API/api_endpoints";
import ReactPlayer from 'react-player';

const Media = ({ userId }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };
  const [pageNo, setPageNo] = useState(1);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true); 
  const [loading, setLoading] = useState(true); 
  const [isFullscreen, setIsFullscreen] = useState(false);

  const playerRef = useRef(null); // Reference for the video player
  const videoWrapperRef = useRef(null); // Reference for the video wrapper

  const handleFullscreen = () => {
    if (videoWrapperRef.current) {
      if (videoWrapperRef.current.requestFullscreen) {
        videoWrapperRef.current.requestFullscreen();
      } else if (videoWrapperRef.current.mozRequestFullScreen) { // Firefox
        videoWrapperRef.current.mozRequestFullScreen();
      } else if (videoWrapperRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        videoWrapperRef.current.webkitRequestFullscreen();
      } else if (videoWrapperRef.current.msRequestFullscreen) { // Internet Explorer/Edge
        videoWrapperRef.current.msRequestFullscreen();
      }
    }
  };
  const fetchData = async () => {
    try {
      setLoading(true)
      const response = await findGallery(pageNo, userId);
      if (response.status) {
        const newItems = response.data.data;

        setItems((prevItems) => [
          ...prevItems,
          ...newItems.data 
        ]);

        if (
          newItems.data.length === 0 || 
          items.length + newItems.data.length >= response.data.totalPostCount
        ) {
          setHasMore(false); 
        }
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); 
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo]); 
  const handleEnd=()=>{
    // alert("oo")
      console.log('Video ended');
      setIsPlaying(false);
    
  }
  
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        // Exit from fullscreen detected
        console.log('Exited fullscreen, stopping the video');
        setIsPlaying(false); // Set isPlaying to false when exiting fullscreen
      }
    };
  
    // Add event listener for fullscreen change
    document.addEventListener('fullscreenchange', handleFullscreenChange);
  
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);
  
  
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };
  
  console.log({isFullscreen})
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(true);
      if (document.fullscreenElement) {
        // Entered fullscreen
        setIsFullscreen(true);
      } else {
        // Exited fullscreen
        setIsFullscreen(false);
        setIsPlaying(false); // Stop playing when exiting fullscreen
      }
    };
  
    // Add event listeners for fullscreen change
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // For older browsers
  
    // Clean up event listeners on component unmount
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
    };
  }, []);
  return (
    <div>
      <div id="scrollableDiv" className="h-[300px] overflow-auto">
        <InfiniteScroll
        style={{overflow:"hidden"}}
          dataLength={items.length}
          next={() => setPageNo((prev) => prev + 1)} 
          hasMore={hasMore} 
           loader={loading && pageNo !==1 && <h4 className="text-base text-center text-[#1D1546]">Loading...</h4>} 
          scrollableTarget="scrollableDiv"
        >
             {loading && <h4 className="text-base text-center text-[#1D1546] mt-[2rem]">Loading...</h4>}
          {items.length === 0 && !loading ? ( 
            <div className="text-base text-center text-[#1D1546] m-4 text-[12px] ">No Media Found.</div>
          ) : (
            <div className="flex flex-wrap gap-[1rem] mt-[2rem] px-[15px]">
              {items?.map((item, index) => (
                <div key={index} className="w-[6rem] h-[5rem]" >
                  {item?.mimeType === "video/quicktime"||item?.mimeType === "video/mp4"? (
                   
                      <div className="video-player2-wrapper"   ref={videoWrapperRef}
                      onClick={()=>{handlePlayButtonClick();
                        handleFullscreen(); // Open in full screen on click
                       }} >
                        <div className="video-container">

                      <ReactPlayer 
                                      ref={playerRef}

                      url={item?.media}
                      playing={isPlaying}
                      controls={isFullscreen ?true:false}  // Disable default controls to hide three dots and all controls
                      width="100%" 
                        height="100%"
                        onPause={() => setIsPlaying(false)} // Update isPlaying when the video is paused
                        onEnded={() => handleEnd()} // Update isPlaying when the video ends
                      /></div>
                      {!isPlaying && (
                        <div className="play-button-overlay" onClick={handlePlayButtonClick}>
                          <button className="play-button">▶</button>
                        </div>
                      )}
                       {isFullscreen && (
        <button className="fullscreen-exit-button" onClick={exitFullscreen}>
          ✖ {/* Custom cross icon */}
        </button>)}
                    </div>
                    
                  ) : (

                    <img
                      src={item.media}
                      alt={item.media || "Media"}

                    className="w-full h-full object-cover"
                    />
                  )}
                </div>
              ))}
            </div>
          )}
          {!hasMore && items.length > 0 && ( 
            <div className="text-base text-center text-[#1D1546]">Yay! You have seen it all</div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Media;
