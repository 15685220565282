// import './App.css';
// import NavBar from './components/NavBar';
// import DownloadBanner from './components/downloadBanner';
// import UserProfile from './screen/user_profile';
// import { Routes, Route } from 'react-router-dom';
// import { useMediaQuery } from "@mui/material";
// import { useEffect, useState } from 'react';

// function App() {
//   const isMobile = useMediaQuery("(max-width: 768px)");
//   const [bannerVisible, setBannerVisible] = useState(isMobile);
//   useEffect(() => {
//     setBannerVisible(isMobile)
//   }, [isMobile])

//   return (
//     <div className="overlay flex flex-col ">
//       <div className="content self-center" >
//         {bannerVisible && (
//           <div >
//             <DownloadBanner visibility={setBannerVisible} />
//           </div>
//         )}
//         <NavBar />
//         <Routes>
//           <Route path="/user_profile/:id" element={<UserProfile />} />
//         </Routes>
//       </div>
//     </div>
//   );
// }

// export default App;
import './App.css';
import NavBar from './components/NavBar';
import DownloadBanner from './components/downloadBanner';
import UserProfile from './screen/user_profile';
import { Routes, Route, Navigate, useParams, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { useEffect, useState } from 'react';

function App() {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [bannerVisible, setBannerVisible] = useState(isMobile);

  useEffect(() => {
    setBannerVisible(isMobile)
  }, [isMobile])

  return (
    <div className="overlay flex flex-col ">
      <div className="content self-center" >
       
        <NavBar />
        {bannerVisible && (
          <div >
            <DownloadBanner visibility={setBannerVisible} />
          </div>
        )}
        <CustomRoutes />

      </div>
    </div>
  );
}
const CustomRoutes = () => {
  return (
    <Routes>
      <Route path="/user_profile/:id" element={<UserProfile />} />
      <Route path="/user_profile/redirect:id" element={<DefaultUserProfileRedirect />} />
      {/* <Route path="/*" element={<DefaultUserProfileRedirect />} /> */}
    </Routes>
  );
};

const DefaultUserProfileRedirect = () => {
  const location = useLocation()

  const redirectId = location.pathname.split("/");
 
  if (location.pathname.includes("/user_profile/redirect")) {
    return <Navigate to={`/user_profile/${redirectId[redirectId?.length-1]}`} replace />;
 //  return window.open(`/user_profile/${redirectId[redirectId?.length-1]}`, "_blank", "noopener,noreferrer");
  }

 
  return null;
};


export default App;