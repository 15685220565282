import { wrappedFetch } from "./urlFunctions";
import API from "./urlConstants";

export const findUserByQR = (data) => {
  return wrappedFetch(API.FIND_USER, "POST", data);
};

export const giveReview = (data) => {
  return wrappedFetch(API.CREATE_GRATITUDE, "POST", data);
};
export const giveImageReview = (data) => {
  return wrappedFetch(API.IMG_GRATITUDE, "POST", data);
};
export const createweb =(data)=>{
  return wrappedFetch(API.CREATE_WEB, "POST", data);

}
export const PIForBoth =(data)=>{
  return wrappedFetch(API.PIFORBOTH, "POST", data);

}
export const USERPROFILECLICK =(data)=>{
  return wrappedFetch(API.USERPROFILECLICK, "POST", data);

}

export const findGallery = (pageNo, userId) => {
  return wrappedFetch(`${API.FIND_GALLERY}?pageNo=${pageNo}&limit=20&userId=${userId}`, "GET");
};

export const FindTeams =(data)=>{
  {console.log(API.FIND_TEAMS, "data")}

  return wrappedFetch(API.FIND_TEAMS, "POST", {});

}


export const fetchSpotlight = (data) => {
  return wrappedFetch(API.FETCH_SPOTLIGHT, "GET");
};